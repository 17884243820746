function Nosotros() {
  return (
    <section
      id="sobrenosotros"
      className="bg-green-50 text-stone-900 flex flex-col justify-center scroll-mt-24 "
    >
      <h2 className=" text-center text-3xl font-bold pt-8">Sobre Nosotros</h2>
      <p className="text-xl py-5 px-5 max-w-[1000px] m-auto mt-5 font-medium">
        Nuestro camping está situado en un hermoso entorno natural de 7
        hectáreas donde anteriormente funcionaba una granja orgánica. <br />{" "}
        <br />
        Nos encontramos sobre al costa del río Quemquentreu, a quince cuadras
        del centro y a 300 metros de la avenida principial de El Bolsón. <br />{" "}
        <br />
        Por nuestra ubicación disfrutamos de una vista privilegiada del cerro
        Piltriquitrón. <br /> <br />
        Contamos con una amplia variedad de servicios y actividades para quienes
        nos visitan como: asadores con mesas y parrillas, cancha de fútbol y
        ping pong, hamaca de altura, quincho comunitario, cocina, baños con agua
        caliente las 24hs, lavarropas de uso común, wi-fi, alquiler de
        guardamochilas, electricidad en todo el camping, bar y mucho más.
      </p>
      <iframe
        className="m-auto mt-20 w-full h-60 sm:h-96 lg:mb-20 lg:w-8/12 lg:h-[450px]"
        width=""
        height=""
        src="https://www.youtube.com/embed/KsSPa5_VNwk"
        title="camping Ni Nada 2023"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </section>
  );
}

export default Nosotros;
