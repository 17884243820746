function Wsp() {
  return (
    <div className="fixed right-4 bottom-4 lg:bottom-6 lg:right-6 z-50">
      <a
        href="https://api.whatsapp.com/send/?phone=5493834781014&text=Hola%20,te%20consulto%20por
        %20el%20camping."
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://img.icons8.com/officel/60/null/whatsapp.png"
          alt="whatsapp"
        />
      </a>
    </div>
  );
}

export default Wsp;
