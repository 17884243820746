import React from "react";
import Carrousel from "./Carrousel";

function Cabaña({ info }) {
  return (
    <div className="w-11/12 lg:w-3/12 lg:m-3  flex-col justify-center items-center mt-3  pb-4 bg-green-50 rounded-lg ">
      <Carrousel imgs={info.img} />
      <h2 className="mt-2 text-lg text-center font-bold mb-1  text-green-950">
        {info.nombre}
      </h2>
      <p className="text-center py-2 px-4 text-green-950">{info.descripcion}</p>
    </div>
  );
}

export default Cabaña;
