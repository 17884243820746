function Mapa() {
  return (
    <section className="flex justify-center mt-3 pb-3 scroll-mt-24" id="mapa">
      <iframe
        title="maps"
        width="95%"
        height="400px"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2966.008713397576!2d-71.54711688494203!3d-41.97862269133154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x961b9561a5b961ff%3A0x3e74e67c0db721e8!2sCamping%20Ni%20Nada!5e0!3m2!1ses!2sar!4v1671551722735!5m2!1ses!2sar"
        loading="lazy"
      ></iframe>
    </section>
  );
}

export default Mapa;
