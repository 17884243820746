import carpa from "../images/fotos/carpa.jpg";
import vistaSuperior from "../images/fotos/vista-superior.jpeg";
import lavaderoPiltri from "../images/fotos/lavadero-piltri.jpg";
import cocina from "../images/fotos/cocina.jpeg";
import cerezas from "../images/fotos/cerezas.jpeg";
import futbol from "../images/fotos/futbol.jpg";
import hamaca from "../images/fotos/hamaca.jpg";
import Entrada from "../images/cabañas/fotos-generales/entrada.jpg";
import Cielo from "../images/cabañas/fotos-generales/cielo.jpg";
import Baños from "../images/cabañas/fotos-generales/baños.jpg";
import campingCarpaAzul from "../images/campingCarpaAzul.jpg";
import campingCarpas from "../images/campingCarpas.jpg";

function Fotos() {
  return (
    <section
      id="fotos"
      className="flex flex-col lg:flex-row lg:flex-wrap justify-center items-center scroll-mt-24"
    >
      <img
        src={vistaSuperior}
        alt="cabaña"
        className=" w-11/12 lg:w-5/12 lg:m-5"
      />
      <img
        src={campingCarpaAzul}
        alt="cabaña"
        className=" mt-4 w-11/12 lg:w-5/12 lg:m-5"
      />
      <img
        className="mt-4 w-11/12 lg:w-5/12 lg:m-5"
        src={lavaderoPiltri}
        alt=""
      />
      <img className="mt-4 w-11/12 lg:w-5/12 lg:m-5" src={carpa} alt="" />
      <img
        src={campingCarpas}
        alt="cabaña"
        className=" mt-4 w-11/12 lg:w-5/12 lg:m-5"
      />
      <img
        className="mt-4 w-11/12 lg:w-5/12 lg:m-5"
        src={hamaca}
        alt="hamaca de altura"
      />

      <img
        className="mt-4 w-11/12 lg:w-5/12 lg:m-5"
        src={cocina}
        alt="cocina"
      />

      <img
        className="mt-4 w-11/12 lg:w-3/12 lg:h-96  lg:m-5"
        src={Entrada}
        alt="Cartel camping ni nada entrada"
      />
      <img
        className="mt-4 w-11/12 lg:w-3/12 lg:h-96  lg:m-5"
        src={Baños}
        alt="baños camping ni nada"
      />
      <img
        className="mt-4 w-11/12 lg:w-3/12 lg:h-96 lg:m-5"
        src={Cielo}
        alt="Cielo desde el camping ni nada el bolson"
      />
      <img
        className="mt-4 w-11/12 lg:w-3/12 lg:m-5"
        src={cerezas}
        alt="cerezas en el camping ni nada el bolson"
      />
      <img
        className="mt-4 w-11/12 lg:w-3/12 lg:m-5"
        src={futbol}
        alt="cancha de futbol camping ni nada el bolson"
      />
    </section>
  );
}

export default Fotos;
