import React from "react";
import CabañaSeisPrimera from "../images/cabañas/cabaña-verde/cabañaverde.jpg";
import CabañaSeisVentana from "../images/cabañas/cabaña-verde/ventana.jpg";
import CabañaSeisBaño from "../images/cabañas/cabaña-verde/baño.jpg";
import CabañaSeisCamas from "../images/cabañas/cabaña-verde/cama-dos-plazas.jpg";
import CabañaSeisCuchetas from "../images/cabañas/cabaña-verde/cuchetas.jpg";
import CabañaCincoPorFuera from "../images/cabañas/dormi-cinco/porFuera.jpg";
import CabañaCincoComedor from "../images/cabañas/dormi-cinco/comedor.jpg";
import CabañaCincoCamaDoble from "../images/cabañas/dormi-cinco/camaDoble.jpg";
import CabañaCincoCamasSimples from "../images/cabañas/dormi-cinco/camasSimples.jpg";
import DormiDobleFuera from "../images/cabañas/dormi-dos/dormiDos.jpg";
import DormiDobleDentro from "../images/cabañas/dormi-dos/dormiDosCamas.jpg";
import Carpa from "../images/cabañas/fotos-generales/carpa.jpg";
import Casilla from "../images/cabañas/fotos-generales/casilla.jpg";
import Baños from "../images/cabañas/fotos-generales/baños.jpg";
import DormiCuatro from "../images/dormi-cuatro.jpg";
import Cabaña from "./Cabaña";

function Cabañas() {
  const cabañas = [
    {
      nombre: "Camping",
      img: [Carpa, Casilla, Baños],
      descripcion:
        "Amplio espacio para acampar, luz electrica en todo el camping, baños con agua caliente 24hs, cocina, zona WI-FI y  todos los servicios.",
    },
    {
      nombre: "Cabaña para 6 u 8 personas",
      img: [
        CabañaSeisPrimera,
        CabañaSeisCamas,
        CabañaSeisBaño,
        CabañaSeisCuchetas,
        CabañaSeisVentana,
      ],
      descripcion:
        "Excelente cabaña con capacidad para seis u ocho personas. Dos habitaciones, cocina equipada, lavadero particular, WI-FI y ropa de cama incluída.",
    },
    {
      nombre: "Dormi para 5 personas",
      img: [
        CabañaCincoPorFuera,
        CabañaCincoCamaDoble,
        CabañaCincoCamasSimples,
        CabañaCincoComedor,
      ],
      descripcion:
        "Cómodo dormi con capacidad para alojar hasta cinco personas. Cuenta con camas dobles y simples. La ropa de cama está incluída.",
    },
    {
      nombre: "Dormi para 4 personas",
      img: [DormiCuatro],
      descripcion:
        "Cómodo dormi con capacidad para cuatro personas, cocina y lavadero compartido, ropa de cama incluída.",
    },
    {
      nombre: "Dormi para 2 personas",
      img: [DormiDobleFuera, DormiDobleDentro],
      descripcion:
        "Cómodo dormi con capacidad para dos personas, cocina y lavadero compartido, ropa de cama incluída.",
    },
  ];
  return (
    <section className="scroll-mt-28" id="queOfrecemos">
      <h2 className="text-3xl lg:text-4xl text-center text-green-50 font-bold lg:py-8 p-4 p mt-4 mb-4 ">
        Opciones para pernoctar
      </h2>
      <div className="flex flex-col lg:flex-row lg:flex-wrap items-center justify-center mt-4">
        {cabañas.map((cabaña, key) => (
          <Cabaña info={cabaña} key={key} />
        ))}
      </div>
    </section>
  );
}

export default Cabañas;
