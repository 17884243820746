function Footer() {
  return (
    <footer className="bg-green-900/70 text-slate-50">
      <ul className="flex justify-center gap-2 py-4">
        <li className="">
          <a
            href="https://www.facebook.com/profile.php?id=100063605491008"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://img.icons8.com/ios-filled/32/FFFFFF/facebook--v1.png"
              alt="facebook"
            />
          </a>
        </li>
        <li className="">
          <a
            href="https://www.instagram.com/camping.ninada/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://img.icons8.com/ios-filled/32/FFFFFF/instagram-new--v1.png"
              alt="instagram"
            />
          </a>
        </li>
        <li className="">
          <a
            href="https://api.whatsapp.com/send/?phone=5493834781014&text=Hola%20,te%20consulto%20por
        %20el%20camping."
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://img.icons8.com/ios-glyphs/32/FFFFFF/whatsapp.png"
              alt="whatsapp"
            />
          </a>
        </li>
      </ul>
      <p className="text-center pb-4 px-4">
        Diseñado por Gonzalo Airasca, todos los derechos reservados.
      </p>
    </footer>
  );
}

export default Footer;
