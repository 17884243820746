function Splash() {
  return (
    <section className="splash bg-center bg-cover pt-20 h-screen flex items-center justify-center ">
      <div className="flex flex-col px-2 m-5 justify-center items-center text-center  text-green-50 h-4/6 ">
        <h1 className="text-4xl font-bold lg:text-6xl sombra ">
          Ni Nada, camping, cabañas y dormis <br /> en El Bolsón, Río Negro.
        </h1>
        <h3 className="mt-4 lg:text-xl text-slate-400 sombra">
          Calle Eusebio Barra, El Bolsón.
        </h3>
        <a href="#sobrenosotros">
          <button className="btn mt-4">Conocenos</button>
        </a>
      </div>
    </section>
  );
}

export default Splash;
