import React from "react";

function Contacto() {
  return (
    <section
      id="contacto"
      className="bg-white text-stone-900 h-80 flex flex-col items-center justify-center scroll-mt-24"
    >
      <h2 className="text-2xl text-center text-semibold mb-6">
        ¡Contactate y sacate las dudas!
      </h2>
      <div className="flex items-center">
        <img
          className="mr-4"
          src="https://img.icons8.com/ios/60/null/iphone13.png"
          alt="celular"
        />
        <p className="text-xl">3834781014 (Quimey) </p>
      </div>
    </section>
  );
}

export default Contacto;
