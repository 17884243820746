import "./App.css";
import Cabañas from "./components/Cabañas";
import Contacto from "./components/Contacto";
import Footer from "./components/Footer";
import Fotos from "./components/Fotos";
import Header from "./components/Header";
import Mapa from "./components/Mapa";
import Nosotros from "./components/Nosotros";
import NuestrasCabañas from "./components/NuestrasCabañas";
import Splash from "./components/Splash";
import Tarifas from "./components/Tarifas";
import Wsp from "./components/Wsp";

function App() {
  return (
    <div className="todo bg-center bg-cover bg-fixed">
      <div className="bg-neutral-800/50">
        <Header />
        <Splash />
        <Nosotros />
        <Cabañas />
        <NuestrasCabañas />
        <Tarifas />
        <Fotos />
        <Mapa />
        <Contacto />
        <Wsp />
        <Footer />
      </div>
    </div>
  );
}

export default App;
