function NuestrasCabañas() {
  return (
    <section id="servicios" className="bg-green-50 mt-5 scroll-mt-24">
      <h2 className="text-center font-bold text-3xl py-10 lg:text-4xl">
        Contamos con:
      </h2>
      <div className="flex flex-wrap justify-between lg:justify-center py-4">
        <div className="text-center m-2 flex flex-col justify-center items-center  w-3/12  lg:w-2/12">
          <img
            src="https://img.icons8.com/ios-glyphs/80/null/wifi--v1.png"
            alt="wifi"
          />
          <h3 className="">Wi-Fi</h3>
        </div>
        <div className="text-center m-2 flex flex-col justify-center items-center w-3/12  lg:w-2/12">
          <img
            src="https://img.icons8.com/dotty/80/null/weber.png"
            alt="Asadores"
          />
          <h3 className="">Asadores</h3>
        </div>
        <div className="text-center m-2 flex flex-col justify-center items-center w-3/12  lg:w-2/12 ">
          <img
            src="https://img.icons8.com/ios/80/null/gas-stove.png"
            alt="cocina"
          />
          <h3 className="">Cocina</h3>
        </div>
        <div className="text-center m-2 flex flex-col justify-center items-center w-3/12  lg:w-2/12">
          <img
            src="https://img.icons8.com/ios/80/null/washing-machine.png"
            alt="lavarropas"
          />
          <h3 className="">Lavarropas</h3>
        </div>
        <div className="text-center m-2 flex flex-col justify-center items-center w-3/12  lg:w-2/12">
          <img
            src="https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/80/null/external-shower-bathroom-kiranshastry-lineal-kiranshastry.png"
            alt="Agua caliente 24hs."
          />
          <h3 className="">
            Agua caliente <br /> 24hs.
          </h3>
        </div>
        <div className="text-center  m-2 flex flex-col justify-center items-center w-3/12 lg:w-2/12 ">
          <img
            src="https://img.icons8.com/external-konkapp-detailed-outline-konkapp/80/null/external-lockers-gym-konkapp-detailed-outline-konkapp.png"
            alt="lockers"
          />
          <h3 className="">Lockers</h3>
        </div>
        <div className="text-center m-2 flex flex-col justify-center items-center w-3/12 lg:w-2/12">
          <img
            src="https://img.icons8.com/pastel-glyph/80/null/fast-food--v2.png"
            alt="comidas rapidas"
          />
          <h3 className="">
            Bar y <br /> comidas rápidas
          </h3>
        </div>
        <div className="text-center m-2 flex flex-col justify-center items-center w-3/12 lg:w-2/12 ">
          <img
            src="https://img.icons8.com/ios-filled/80/null/football2.png"
            alt="cancha de futbol"
          />
          <h3 className="">
            Cancha <br /> de fútbol
          </h3>
        </div>
        <div className="text-center m-2 flex flex-col justify-center items-center w-3/12 lg:w-2/12 ">
          <img
            src="https://img.icons8.com/ios/80/null/ping-pong.png"
            alt="ping pong"
          />
          <h3 className="">
            Mesa de <br /> Ping-Pong
          </h3>
        </div>
        <div className="text-center m-2 flex flex-col justify-center items-center w-3/12  lg:w-2/12">
          <img
            src="https://img.icons8.com/carbon-copy/80/null/swingset.png"
            alt="hamaca de altura"
          />
          <h3 className="">
            Hamaca <br /> de altura
          </h3>
        </div>
        <div className="text-center m-2 flex flex-col justify-center items-center w-3/12  lg:w-2/12">
          <img
            src="https://img.icons8.com/ios-filled/80/null/cherry.png"
            alt="arboles de cerezas"
          />
          <h3 className="">
            Árboles <br /> de cereza
          </h3>
        </div>
        <div className="text-center m-2 flex flex-col justify-center items-center w-3/12 lg:w-2/12 ">
          <img
            src="https://img.icons8.com/ios/80/null/backpack.png"
            alt="alquiler guardamochilas"
          />
          <h3 className="">
            Alquiler <br /> guarda <br /> mochilas
          </h3>
        </div>
        <div className="text-center m-2 flex flex-col justify-center items-center w-3/12 lg:w-2/12 ">
          <img
            src="https://img.icons8.com/ios-filled/80/light-on.png"
            alt="light-on"
          />
          <h3 className="">
            Electricidad <br /> en todo el <br /> camping
          </h3>
        </div>
      </div>
    </section>
  );
}

export default NuestrasCabañas;
