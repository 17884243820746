/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import logo from "../images/logoChico.png";

const Menu = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4 18H20C20.55 18 21 17.55 21 17C21 16.45 20.55 16 20 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3 7C3 7.55 3.45 8 4 8H20C20.55 8 21 7.55 21 7C21 6.45 20.55 6 20 6H4C3.45 6 3 6.45 3 7Z"
        fill="white"
      />
    </svg>
  );
};

function Header() {
  const [nav, setNav] = useState(false);
  return (
    <header className=" w-full flex justify-between fixed top-0 left-0 z-50 drop-shadow-xl bg-green-900/70 h-24">
      <div
        className="flex
      ml-4 lg:ml-10  items-center"
      >
        <a href="#">
          <img
            src={logo}
            alt="Logo ni nada camping, el bolson, rio negro, argentina"
            className="h-20"
          />
        </a>
      </div>
      <div className=" flex mr-4 lg:mr-10  items-center  ">
        <div className="lg:hidden" onClick={() => setNav(!nav)}>
          <Menu />
        </div>
        <ul className="hidden lg:flex gap-10 justify-between text-green-50">
          <li className="cursor-pointer  hover:opacity-40">
            <a href="#sobrenosotros"> Sobre Nosotros</a>
          </li>
          <li className="cursor-pointer hover:opacity-40">
            <a href="#queOfrecemos"> ¿Qué ofrecemos?</a>
          </li>
          <li className="cursor-pointer hover:opacity-40">
            <a href="#servicios">Servicios</a>
          </li>
          <li className="cursor-pointer hover:opacity-40">
            <a href="#tarifas">Tarifas</a>
          </li>
          <li className="cursor-pointer hover:opacity-40">
            <a href="#fotos"> Fotos</a>
          </li>
          <li className="cursor-pointer hover:opacity-40">
            <a href="#mapa"> Cómo llegar</a>
          </li>
          <li className="cursor-pointer hover:opacity-40">
            <a href="#contacto"> Contacto</a>
          </li>
        </ul>
      </div>
      <nav
        className={
          nav ? "fixed right-0 top-24 font-semibold bg-green-900/90" : "hidden"
        }
      >
        <ul className="text-slate-50 mr-3">
          <li className="m-3" onClick={() => setNav(false)}>
            <a href="#sobrenosotros"> Sobre Nosotros</a>
          </li>
          <li className="m-3" onClick={() => setNav(false)}>
            <a href="#queOfrecemos"> ¿Qué ofrecemos?</a>
          </li>
          <li className="m-3" onClick={() => setNav(false)}>
            <a href="#servicios">Servicios</a>
          </li>
          <li className="m-3" onClick={() => setNav(false)}>
            <a href="#tarifas">Tarifas</a>
          </li>
          <li className="m-3" onClick={() => setNav(false)}>
            <a href="#fotos"> Fotos</a>
          </li>
          <li className="m-3" onClick={() => setNav(false)}>
            <a href="#mapa"> Cómo llegar</a>
          </li>
          <li className="m-3" onClick={() => setNav(false)}>
            <a href="#contacto"> Contacto</a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
